import type { Design } from '@amedia/brick-tokens';
import { renderBrickIcon } from '@amedia/brick-icon/template';

import { getStyleClasses, pillTextStyle } from './brick-pill-styles';
import type { BrickPillData, CountdownData } from './types';
import { countdownTemplate } from './lib/countdownUtils';
import {
  iconOnly,
  iconPillsVersions,
  mapToColorTheme,
  mapToVersion,
} from './lib/utils';

function getIconId(version: string) {
  switch (version) {
    case 'plus':
      return version;
    case 'plusall':
      return version;
    default:
      return `pill-${version}`;
  }
}

export const pillIcon = (data: BrickPillData, isServerSide: boolean) => {
  const { version = 'vignette', iconText } = data;

  if (iconPillsVersions.includes(version)) {
    const iconId = getIconId(version);
    return isServerSide
      ? renderBrickIcon({ dataIconId: iconId, dataIconText: iconText })
      : `<brick-icon-v2 data-icon-id="${iconId}" data-icon-text="${iconText}"></brick-icon-v2>`;
  }
  return '';
};

const iconOnlyTemplate = (data: BrickPillData, isServerSide: boolean) =>
  `${pillIcon(data, isServerSide)}`;

const defaultMarkup = (data: BrickPillData, isServerSide: boolean) => {
  const icon = pillIcon(data, isServerSide);

  return `${icon}
<span class="${pillTextStyle({ icon: !!icon })}" title="${data.text}">  
  ${data.text}
</span>`;
};

export const brickPillTemplate = (
  data: BrickPillData,
  isServerSide: boolean,
  timedata?: CountdownData
) => {
  if (!data) {
    return '';
  }

  const { version = 'vignette', filled = false, skin = 'none', text } = data;

  let markup = '';

  if (version === 'countdown') {
    return countdownTemplate(data, isServerSide, timedata);
  }

  if (!text || iconOnly.includes(version)) {
    markup = iconOnlyTemplate(data, isServerSide);
  } else {
    markup = defaultMarkup(data, isServerSide);
  }

  if (isServerSide) {
    return `<brick-pill is-rendered data-version="${version}" 
    data-filled="${filled}" data-skin="${skin}" class="${getStyleClasses({
      version,
      filled,
      skin,
    })}">${markup}</brick-pill>`;
  }

  return markup;
};

export function renderBrickPill(pillData: BrickPillData) {
  const version = mapToVersion(pillData.version);
  const skin = mapToColorTheme(pillData.skin);
  const markup = brickPillTemplate({ ...pillData, skin, version }, true);

  return markup;
}

export { renderBrickPillbox } from './lib/pillboxTemplate';

export { getCssText } from '@amedia/brick-tokens';
